














import { Component, Vue } from 'vue-property-decorator'
import UserService from '@/services/UserService'
import store from '@/store'
import Auth from '@/store/modules/Auth'
import { getModule } from 'vuex-module-decorators'

const AuthModule = getModule(Auth, store)

@Component
export default class VerifyPage extends Vue {
  private spining: boolean = true

  created() {
    setTimeout(() => {
      this.verifyEmailChange()
    }, 2 * 1000)
  }

  verifyEmailChange() {
    if (this.$route.query.token) {
      let data = {
        params: { token: this.$route.query.token }
      }
      UserService.verifyEmailChange(this.$route.query.user_id, data)
        .then(() => {
          this.$bvModal.show('modal-success')
        })
        .catch(() => {
          this.$bvModal.show('modal-error')
        })
        .finally(() => (this.spining = false))
    }
  }

  jumpToLogin() {
    this.$router.push('login')
    AuthModule.SET_TOKEN_NULL()
    sessionStorage.clear()
    localStorage.clear()
  }
}
